#Nav3_0 .ant-menu-submenu-title > .header3-item-block > .kf1k8185gg-editor_css {
  opacity: 0.67;
}
#Content5_0 .ant-col > .content5-block-content > .kf1kko5nnq-editor_css {
  display: none;
}
#Banner5_0 .home-page > .kf1ldtr1dlt-editor_css {
  width: 10%;
  right: 16%;
  z-index: 100%;
}
#Banner5_0 .home-page > .banner5-title-wrapper > .kf1lneffjpp-editor_css {
  width: 100%;
}
#Content5_0 .ant-col > .content5-block-content > .kf1luorebg-editor_css {
}
#Nav3_0 .home-page > .kf1la9uxzn-editor_css {
  border-top-width: 10px;
  border-bottom-width: 10px;
  width: 50px;
  position: static;
  top: 10%;
  bottom: 10%;
  float: left;
  opacity: 0.85;
  margin: 10px 0;
}
#Feature1_0 .ant-row > .ant-col > .kf1mo4zfpb9-editor_css {
  width: 40%;
}
#Feature1_0 .kf1nbsacl16-editor_css {
  background-color: rgba(0, 0, 0, 0);
}
#Content5_0 .ant-col > .content5-block-content > .kf1nkomypws-editor_css {
  color: #000000;
}
#Content5_0 .ant-col > .content5-block-content > .kf1nl71b648-editor_css {
  color: #000000;
}
